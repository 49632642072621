import React from 'react';
import { useRouter } from 'next/router';

export default function Index() {
  const router = useRouter();

  router.push('https://getoutfit.ae/mobileapp');

  return <>Redirecting...</>;
}
